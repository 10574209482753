/** Container Styles */

html, body {
    background-color: #f0f0f0;
    font-family: 'Roboto', sans-serif;
    font-size: 11pt;
    line-height: 1.5;
}

.container {
    margin: 0;
    padding: 0;
    background-color: white;
}

@page {
    size: letter;
    margin: 0.5in;
}

@media screen {
    html, body {
        text-align: center;
    }

    .container {
        max-width: 6.5in;
        display: inline-block;
        text-align: left;
        margin: 1rem;
        padding: 1rem 1rem 1rem 1rem;
    }
}

@media screen and (min-width: 768px) {
    .container {
        margin: 2rem;
        padding: 0.5in 1in 1in 1in;
    }
}

/** Icon Fonts */

.icon {
    display: inline-block;
    font: normal normal normal 14px/1 fontello;
    font-size: inherit;
    text-rendering:auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-github-circled:before { content: '\f09b'; }
.icon-linkedin:before { content: '\f0e1'; }
.icon-stackoverflow:before { content: '\f16c'; }

/** Element Styles */

h1, h2, h3, h4, strong, .period {
    font-weight: 500;
}

h2 {
    text-align: center;
    text-transform: uppercase;
    font-size: 14pt;
}

h3, h4 {
    font-size: 11pt;
}

h2 {
    margin-top: 36pt;
    margin-bottom: 16pt;
}

h3, h4 {
    margin-bottom: 0.5rem;
}

ul, p {
    margin-top: 0.5rem;
}

h2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
}

h2:before, h2:after {
    background-color: #970800;
    -webkit-print-color-adjust: exact;
    content: '\a0';
    flex-grow: 1;
    height: 1px;
    position: relative;
    top: 0.7em;
}

h2:before {
    margin-right:2rem;
}

h2:after {
    margin-left:2rem;
}

/** Header */

header {
    text-align: center;
    color: #5A5A5A;
}

header h1 {
    margin-top: 0;
    color: black;
}

header a {
    text-decoration: none;
    color: #5a5a5a;
}

header a:hover {
    color: #8a8a8a;
}

header .avatar {
    display: inline-block;
    width: 1.5in;
    height: 1.5in;
    content: url(https://secure.gravatar.com/avatar/4ee50711327dbd504750720163a28cbf?s=512);
    border-radius: 0.75in;
}

header ul {
    list-style: none;
    padding: 0;
    margin-bottom: -1.25rem;
}

header li {
    display: inline-block;
    padding: 0.5rem;
}

header li a {
    display: inline-block;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    line-height: 3rem;
    font-size: 1.25rem;
    background-color: #f0f0f0;
    color: #5A5A5A;
}

header li a:hover {
    background-color: #eaeaea;
    color: black;
}

/** Education */

.education .school {
    position: relative;
}

.education span {
    display: block;
}

@media print, (min-width: 768px) {
    .education .right {
        position: absolute;
        top: 0;
        right: 0;
        text-align: right;
    }
}

/** Skills and Interests */

.skills-intersts {
    page-break-after: always;
    position: relative;
}

/** Work Experience */

.experience div {
    position: relative;
}

.experience p.period {
    display: none;
}

.experience p {
    position: relative;
    margin-left: 2rem;
}

@media print, (min-width: 768px) {
    .experience p.period {
        position: absolute;
        display: initial;
        margin-top: 0;
        right: 0;
        top: 0;
    }
}

.experience .work:last-child .description {
    margin-bottom: 0;
}
